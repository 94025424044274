.react-chatbot-kit-chat-bot-message {
  padding: 10px;
  border-radius: 16px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: medium;
  position: relative;
  width: 184.5px;
  margin-left: 0;
  margin-right: auto;
  text-align: left;
}

.react-chatbot-kit-user-chat-message {
  border-radius: 16px;
}

.react-chatbot-kit-chat-container {
  width: 93%;
  height: 600px;
  border-width: 2px;
  border-radius: 0.5rem;
}

.react-chatbot-kit-chat-header {
  font-family: "Inter";
}

.react-chatbot-kit-chat-inner-container {
  height: 90%;
}

.react-chatbot-kit-chat-message-container {
  height: 100%;
}

.react-chatbot-kit-chat-bot-message {
  width: max-content;
}

.bot-avatar {
  border: 2px solid #9d9bff;
  border-radius: 50px;
}
